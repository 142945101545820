<i18n locale="th" lang="yaml" >
page.title : "สร้าง Ticket ทั่วไป"
page.description : "สร้าง Ticket แบบไม่ระบุ Drone เพื่อสอบถามปัญหาทั่วไป เช่น ไม่สามารถใช้งาน HGMC ได้ หรือ ปั๊ม Lot ล่าสุดมีปัญหาหรือไม่"

ticket_create.confirm.title : "ยืนยันการเปิด Ticket"
ticket_create.confirm.message : "คุณต้องการยืนยันการเปิด Ticket ตัวนี้ ?"
ticket_create.success : "เปิด Ticket เรียบร้อยด้วย เลข Ticket {ticket}"
ticket_create.submit.label : "เปิด Ticket"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')" />
		<a-card :bordered="false">
			<TicketForm
				ref="formRef"
				:loading="loading"
				mode="create-general"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('ticket_create.submit.label')}}
					</a-button>
				</template>
			</TicketForm>
		</a-card>
	</div>
</template>

<script>
import TicketForm from "@components/ticket/TicketForm.vue"
import axios from "axios"
export default {
	components : {
		TicketForm,
	} ,
	page() {
		return {
			title : this.$t('page.title')
		}
	},
	data() {
		return {
			loading : false ,
			isGeneralTicket : true,
		}
	} ,
	methods : {
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('ticket_create.confirm.title') ,
				content : this.$t('ticket_create.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true

					axios.post('/api/tickets/create',formData).then((response)=>{
						const ticket = response.data.data.ticket;

						this.$message.success(this.$t('ticket_create.success',{"ticket" : ticket.ticket_no}))
						this.$router.push({name:'ticket/view',params: {id : ticket.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			});
		} ,

	}
}
</script>

<style lang="less" scoped>

</style>
